import React, { useState, useEffect } from "react";
import { LangContext } from "../../context/LangContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Banner from "../../components/Banner";
import Service from "../../components/Service";
import DigitalServices from "../../components/DigitalServices";
// import CSR from '../../components/CSR';
// import Videos from '../../components/Videos';
import News from "../../components/News";
import MobileBank from "../../components/MobileBank";
import Information from "../../components/Information";
// import CeoMessage from '../../components/CeoMessage';
import EMICalculator from "../../components/EMICalculator";
import Forex from "../../components/Forex";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./home.scss";
import DepositCalculator from "../../components/DepositCalculator";
import Loader from "../../common/Loader";
import PopUpOnce from "../../components/PopUpOnce";
import VisitCard from "../../components/LastVisited/VisitCard";
import RecurringCalculator from "../../components/RecurringCalculator";
import Testimonial from "../../components/Testimonial";

const Home = () => {
  const [isPopTime, setIsPopTime] = useState(false);

  const lang = React.useContext(LangContext);
  const np = lang.state.np;

  const getTime = localStorage.getItem("lastVisit");

  console.log("getTime =>", getTime);

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["homeData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/home`)
        .then((res) => res.data),
  });

  useEffect(() => {
    if (data && !isLoading) {
      const timer = setTimeout(() => setIsPopTime(true), 5000);
      return () => clearTimeout(timer);
    }
  }, [data, isLoading]);

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;
  // popups
  return (
    <>
      {/* <div style={{minHeight: '100vh'}}>
    <h1>hello</h1>
     </div>  */}
      {isPopTime && <PopUpOnce values={data.popups} />}
      <Banner data={data && data.banners} />
      <Service />
      <DigitalServices data={data && data.digital_slider} />
      {/* <CSR /> */}
      {/* <Videos data={data.videos} /> */}
      <Forex data={data && data.forex} />

      <News
        dataSlider={data && data.news_slider}
        dataNews={data && data.news}
      />

      <div className="homeCalcBgContainer">
        <div className="homeCalcContainer">
          <h2>{np ? "क्याल्कुलेटरहरू" : "Calculators"}</h2>
          <Tabs className="tabs" defaultIndex={0}>
            <TabList className="tabList">
              <Tab key={0} className="button">
                {np ? "कर्जा" : "EMI Calculator"}
              </Tab>
              <Tab key={1} className="button">
                {np ? "बचत" : "Recurring Deposit Calculator"}
              </Tab>
            </TabList>

            <TabPanel key={0}>
              <EMICalculator />
            </TabPanel>

            <TabPanel key={1}>
              <RecurringCalculator />
            </TabPanel>
          </Tabs>
        </div>
      </div>

      {/* <Testimonial testimonials={data && data.testimonials} />
      <MobileBank count={data && data.count} /> */}
      {/* <digitalIn digital={data.digital_banking} /> */}
      <Information
        digital={data.digital_banking}
        compliance={data.compliance_officer}
        grievance={data.grievance_officer}
        information={data.information_officer}
      />

      {getTime && <VisitCard data={JSON.parse(getTime)} />}
    </>
  );
};

export default Home;
